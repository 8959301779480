import * as React from "react";

export const CourseItem = (props) => {
  let { dataItem, selected, ...others } = props;
  // debugger
  // console.log(props);
  return (
    <li {...others}>
      <div style={{ width: 20 }} className="order">
        <span> {props.dataItem.order !== 0 && props.dataItem.order} </span>
      </div>
      <div>
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {props.dataItem.courseNum}, {props.dataItem.section}.

        </span>
        <br />
        {props.dataItem.title} 
        {/* <br /> */}
        <span className="font-italic">with Prof. {props.dataItem.instructorLastName} {props.dataItem.instructorFirstName}</span> <span> ({props.dataItem.units} units)</span>
        {props.dataItem.rankingRequired && <span className="font-italic text-warning"> * (ranking required) </span>}
      </div>
    </li>
  );
};
