import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  processListBoxDragAndDrop
} from "@progress/kendo-react-listbox";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
  ListView,
  ListViewHeader,
  ListViewFooter,
} from "@progress/kendo-react-listview";

import { CourseItem } from "./CourseItem";


import {initElectiveCourseOptions, initCriminalLawPreferences, initPropertyLawPreferences} from "../helper";

const SELECTED_FIELD = "selected";
export const ReviewPreferences = (data) => {   
  //debugger;
  // console.log('d:');
  // console.log(data.criminalLawPreferences);
  const [state, setState] = React.useState({    
    electiveCourseOptions: initElectiveCourseOptions(data),    
    electivePreferences: data.electivePreferences,
    criminalLawPreferences: initCriminalLawPreferences(data),
    propertyLawPreferences: initPropertyLawPreferences(data),
    confirmedPreferences: false,
    draggedItem: {},
  });
  
  return (formRenderProps) => (    
    <div className="" style={{paddingTop:40}}>                                   
        <div className="row justify-content-center preference-section k-content wide">                                
          <div className="col"> 
            <h6>Review your preferences: </h6>  
            <ListView
                data={state.electivePreferences}
                //item={CourseItem}
                item={(props) => <div className="custom-item">{ CourseItem({dataItem: props.dataItem, selected: props.selected}) }</div>}
                style={{
                  width: "100%",
                }}
                // header={MyHeader}
                // footer={MyFooter}
              />

                {/* {state.electiveCourseOptions.map((item, index) => CourseItem({dataItem: item, selected: item.selected}))} */}
                <br/>
                <br/>
                <Field
                  name={"confirmedPreferences"}
                  label={"I have reviewed my preferences and confirm that they are correct."}                  
                  checked={state.confirmedPreferences}
                  required={true}
                  onChange={(e) => setState({...state, confirmedPreferences: e.value})}
                  // type={"text"}
                  component={Checkbox}
                />
            </div>                  
        </div>     
        <style>
          {`            
            .custom-item {
              padding: 10px;
              list-style-type: none; /* Remove bullets */
              padding-left: 0;       /* Remove default padding */
              border-bottom: 1px solid #ccc;
            }

            .custom-item li .order{
                display:block;
                float:left;
              }
          
          `}
      </style>
    </div>
  );
};