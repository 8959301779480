import * as React from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

const types = ['pulsing', 'infinite-spinner', 'converging-spinner'];

export const PageLoader = () => {  
  const isFetching = useIsFetching()
  return isFetching ?  <Loader size="medium" type='pulsing' /> : "";
};
