import { PageLoader } from "./PageLoader";

const ApiStatus = ({ status, error }) => {
  //console.log(error);
  switch (status) {
    case "error": {
      return (
        <div className="alert alert-danger">
          <h4 className="alert-heading">Error</h4>
          <p>{error?.message}</p>
        </div>
      );
    }
    case "idle": {
      return (
        <div className="alert alert-info">
          <h4 className="alert-heading">Info</h4>
          <p>Waiting for user input</p>
        </div>
      );
    }
    case "loading": {
      return <PageLoader />;
    }
    default:
      throw Error("Unknown API state");
  }
};

export default ApiStatus;
