import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import { FormInput, FormDatePicker } from "./form-components";
import { requiredValidator, fieldValidator } from "./validators";
import { DateInput } from "@progress/kendo-react-dateinputs";
import ValidationSummary from "./ValidationSummary";
import { getValue } from "@testing-library/user-event/dist/utils";
import ApiStatus from "./ApiStatus";
import { PageLoader } from "./PageLoader";
import { PageMutating } from "./PageMutating";
import DOMPurify from 'dompurify';
import { DateTime, Settings } from "luxon";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { useFetchEditor, useUpdateEditor  } from "../services/settingsService";
import { RolesAuthRoute } from "./RolesAuthRoute";

const {  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  ForeColor,
  BackColor,
  CleanFormatting,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  NumberedList,
  BulletedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  InsertFile,
  SelectAll,
  Print,
  Pdf,
  TableProperties,
  TableCellProperties,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell } = EditorTools;


  export const EditorComponent = ({editorId, editRoles, editClaims}) => {  
    //const settingId = 'RecordsWebsiteSetting'
    const handleSelect = (e) => {            
      if(e.item?.text)
        setMode(e.item?.text);
    }

    const { data, status, isSuccess, isError, error } = useFetchEditor(editorId);
    const [mode , setMode] = React.useState("View");
    const updateMutation = useUpdateEditor(editorId, () => setMode("View"));
    
  
    if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
    if (!data) return <div> Not found.</div>;
    
    return (
      <>
      <div className="editor-container">
        <div className="gear-menu">
          <RolesAuthRoute claims={editClaims} roles={editRoles}>
            <Menu style={{maxWidth:80, float:'right'}} openOnClick={true} onSelect={handleSelect} >
              <MenuItem text="" icon="gear"  >
                <MenuItem text="Edit" />            
              </MenuItem>                
            </Menu>     
          </RolesAuthRoute>
        </div>       
          { mode === "View" && <EditorComponentView data={data} /> }
        <RolesAuthRoute claims={editClaims} roles={editRoles}>
          { mode === "Edit" && <EditorComponentForm data={data} eventMutation={updateMutation} setMode={setMode} />}
        </RolesAuthRoute>
      </div>   
      </>
    );
  };

export const EditorComponentForm = ({ data, eventMutation, setMode }) => {      

  const {isLoading } = eventMutation;   
  const editor = React.createRef();
  
  const handChange = (e, formRenderProps) => {    
    formRenderProps.onChange("htmlContent", { value: EditorUtils.getHtml(editor.current.view.state) })
  }

  return (
    <>        
    <Form
      onSubmit={(d) => 
        {                                       
            eventMutation.mutate(
            {...d , 
              htmlContent: EditorUtils.getHtml(editor.current.view.state),
               ModifiedAt: (DateTime.fromJSDate(d.availabilityEnds)).toISO(),                             
            })             
        }}      
      initialValues={data}                        
      render={(formRenderProps) => (
        <FormElement
          // style={{
          //   maxWidth: '100%',
          // }}
        >          
          <fieldset className={"k-form-fieldset"}>           
            {eventMutation.isError && <ValidationSummary error={eventMutation.error} />}
            {formRenderProps.visited &&
              formRenderProps.errors &&
              formRenderProps.errors.VALIDATION_SUMMARY && (
                <div className={"k-messagebox k-messagebox-error"}>
                  {formRenderProps.errors.VALIDATION_SUMMARY}
                </div>
              )}                                                                                        
            <Editor
                tools={[
                  [Bold, Italic, Underline, Strikethrough],
                  [Subscript, Superscript],
                  ForeColor,
                  BackColor,
                  [CleanFormatting],
                  [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                  [Indent, Outdent],
                  [OrderedList, UnorderedList],
                  [NumberedList, BulletedList],
                  FontSize,
                  FontName,
                  FormatBlock,
                  [SelectAll],
                  [Undo, Redo],
                  [Link, Unlink, InsertImage, ViewHtml],
                  [InsertTable, InsertFile],
                  [Pdf, Print],
                  [TableProperties, TableCellProperties],
                  [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                  [DeleteRow, DeleteColumn, DeleteTable],
                  [MergeCells, SplitCell],
                ]}
                name={"htmlContent"} 
                //data={ data?.visibleInfo && EditorUtils.setHtml(view, textarea.current.value)}
                defaultContent={data?.htmlContent}
                onChange={(e) => handChange(e, formRenderProps)}
                contentStyle={{
                  height: 160,
                }}
                //defaultContent={"<p>editor sample html</p>"}
                ref={editor}    />            
          </fieldset>                    
          <div style={{marginBottom:15, marginTop:10}}>
            <button
              type={"submit"}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              disabled={!formRenderProps.allowSubmit || isLoading}
              onClick={(e) => { 
                debugger;
                e.preventDefault();  
                 eventMutation.mutate(
                {...data , 
                  htmlContent: EditorUtils.getHtml(editor.current.view.state),
                   ModifiedAt: (DateTime.fromJSDate(data.availabilityEnds)).toISO(),                             
                })}}
            >              
              Save
            </button>       
            <button
              type={"submit"}
              style={{marginLeft:10}}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              disabled={isLoading}
              onClick={(e) => setMode("View")}
            >              
              Cancel
            </button>       
           <PageMutating/>
          </div>
        </FormElement>
      )}
    />
    </>
  );
};

export const EditorComponentView = ({ data }) => {          
  return (
    <>             
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.htmlContent) }} /> 
    </>
  );
};


