import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  processListBoxDragAndDrop
} from "@progress/kendo-react-listbox";
import { CourseItem } from "./CourseItem";
import {initElectiveCourseOptions, initCriminalLawPreferences, initPropertyLawPreferences} from "../helper";
import { EditorComponent } from "../../../components/EditorComponent";

const SELECTED_FIELD = "selected";
export const ElectivePreference = (data, electiveCourseOptions) => {   
    
  const [state, setState] = React.useState({
    electiveCourseOptions: initElectiveCourseOptions(data),    
    electivePreferences: data.electivePreferences,
    criminalLawPreferences: initCriminalLawPreferences(data),
    propertyLawPreferences: initPropertyLawPreferences(data),
    draggedItem: {},
    confirmedPreferencesError: ''
  });

  //const [confirmedPreferencesError, setConfirmedPreferencesError] = React.useState('');

  const handleItemClick = (event, data, connectedData) => {
    setState({
      ...state,
      [data]: state[data].map((item, index) => {
        if (item.id === event.dataItem.id) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: state[connectedData].map((item, index) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  const handleToolBarClick = (e, formRenderProps) => {
    //debugger;
    let toolName = e.toolName || "";
    if(toolName === "transferFrom")
    {
      //debugger;
      if(state.electivePreferences.find(a => a.selected === true)?.rankingRequired)
      {                
         setState( { ...state, confirmedPreferencesError: "You need to rank the following course and can't remove from the preference section."});          
         return;
      }      
    }    

    let result = processListBoxData(
      state.electiveCourseOptions,
      state.electivePreferences,
      toolName,
      SELECTED_FIELD
    );
    result.listBoxTwoData = result.listBoxTwoData.map((item, index)  => { return {...item, order: index + 1} });
    result.listBoxOneData = result.listBoxOneData.map((item, index)  => { return {...item, order: 0} });

    setState({
      ...state,
      electiveCourseOptions: result.listBoxOneData,
      electivePreferences: result.listBoxTwoData,
      confirmedPreferencesError: ""
    });
    formRenderProps.onChange("electiveCourseOptions", { value: result.listBoxOneData.map(a => a)});  
    formRenderProps.onChange("electivePreferences", { value: result.listBoxTwoData.map(a => a)});
  };

  const handleDragStart = (e) => {
    setState({
      ...state,
      draggedItem: e.dataItem,
    });
  };

  const handleDrop = (e, formRenderProps) => {    
    //debugger;
    let result = processListBoxDragAndDrop(
      state.electiveCourseOptions,
      state.electivePreferences,
      state.draggedItem,
      e.dataItem,
      "id"
    );

    // if(["CriminalLaw", "PropertyLaw"].includes(state.draggedItem.category))
    // {
    //     const selected = state.electivePreferences.find(a => a.id === state.draggedItem.id);
    //     if(selected && result.listBoxOneData.any(s => s.id === selected.id))
    //     {
    //         return;
    //     }
    // }   

    result.listBoxTwoData = result.listBoxTwoData.map((item, index)  => { return {...item, order: index + 1} });
    result.listBoxOneData = result.listBoxOneData.map((item, index)  => { return {...item, order: 0} });

    setState({
      ...state,
      electiveCourseOptions: result.listBoxOneData,
      electivePreferences: result.listBoxTwoData,
    });    
    //debugger;    
    formRenderProps.onChange("electiveCourseOptions", { value: result.listBoxOneData.map(a => a)});  
    formRenderProps.onChange("electivePreferences", { value: result.listBoxTwoData.map(a => a)});
  };  
   
  // const consentValidator = (value) => {
  //   //debugger;
  //   return value.length < 11 ? "should be more than 11" : "";
  // }

  const editRoles = ['Manager', 'Admin'];
  const editClaims = ['LawNET Development'];

  return (formRenderProps) => (
    <div className="" >                    
          {/* {data.dragItem} */}
          { state.confirmedPreferencesError && (                  
                  <div className="k-messagebox k-messagebox-error">
                      {state.confirmedPreferencesError}
                  </div>                                          
              )}
        <div className="row justify-content-center preference-section k-content wide">
        <EditorComponent editorId={"EditorSetting_FirstYearPreference_Form_ElectivePreferences_Intro"} editRoles= {editRoles} editClaims={editClaims}  />
          <div className="row  justify-content-center "> 
          <img
              src="https://demos.telerik.com/kendo-ui/content/web/listbox/arrow-left2right.png"
              alt="KendoReact ListBox Left Arrow"
              className="arrow"
              style={{width: 'auto'}}
            />
          </div>            
            <br />          
            <div className="col"> 
                <h6>Course Options </h6>    
                <Field
                key={"electiveCourseOptions"}
                id={"electiveCourseOptions"}
                name={"electiveCourseOptions"}                
                textField="name"                                
                component={ListBox}          
                //onDragStart={handleDragStart}
                // onDrop={                     
                //   (e) => handleDrop(e, formRenderProps)                                      
                // }
                data={ state.electiveCourseOptions ?? [] }
                selectedField={SELECTED_FIELD}
                onItemClick={(e) => 
                  handleItemClick(e, "electiveCourseOptions", "electivePreferences")
                }
                //validator={emailValidator}
                item={CourseItem}               
                />      
          </div>       
          <div className="col"> 
             <h6 style={{marginLeft:50}}>Select and order by preference</h6>
                <Field
                  key={"electivePreferences"}
                  id={"electivePreferences"}
                  name={"electivePreferences"}                                    
                  component={ListBox}          
                  data={ state.electivePreferences ?? []}
                  selectedField={SELECTED_FIELD}
                  onItemClick={(e) =>                    
                      handleItemClick(e, "electivePreferences", "electiveCourseOptions")                       
                  }
                  // style={{
                  //   marginLeft: "12px",
                  // }}
                  onDragStart={handleDragStart}
                  //validator={consentValidator} 
                  onDrop={                     
                    (e) => handleDrop(e, formRenderProps)                                        
                  }
                  item={CourseItem}
                  toolbar={() => {
                    return (
                      <div style={{ margin: 5 }}> 
                        <ListBoxToolbar                      
                          tools={[
                            "moveUp",
                            "moveDown",
                            "transferTo",
                            "transferFrom",
                            //"transferAllTo",
                            //"transferAllFrom",
                            //"remove",
                          ]}
                          
                          data={state.electiveCourseOptions}
                          dataConnected={state.electivePreferences}
                          onToolClick={ (e) => 
                              handleToolBarClick(e, formRenderProps)                            
                          }
                        />
                      </div>
                    );
                  }}
                //validator={emailValidator}
              />  
            </div> 
          <div className="row  justify-content-center "> 
            <img
                src="https://demos.telerik.com/kendo-ui/content/web/listbox/arrow-right2left.png"
                alt="KendoReact ListBox Right Arrow"
                className="arrow"
                style={{width: 'auto'}}
              />
          </div>
        
        </div>     
    </div>
  );
};