import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import { FormInput, FormDatePicker } from "../../components/form-components";
import { requiredValidator, fieldValidator } from "../../components/validators";
import { DateInput } from "@progress/kendo-react-dateinputs";
import ValidationSummary from "../../components/ValidationSummary";
import { getValue } from "@testing-library/user-event/dist/utils";
import ApiStatus from "../../components/ApiStatus";
import { PageLoader } from "../../components/PageLoader";
import { PageMutating } from "../../components/PageMutating";
import DOMPurify from 'dompurify';
import { DateTime, Settings } from "luxon";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { NumericTextBox } from "@progress/kendo-react-inputs";

import {  
  Hint  
} from "@progress/kendo-react-labels";
import { triggerEvents } from "../../services/triggerEventService";
const {  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  ForeColor,
  BackColor,
  CleanFormatting,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  NumberedList,
  BulletedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  InsertFile,
  SelectAll,
  Print,
  Pdf,
  TableProperties,
  TableCellProperties,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell } = EditorTools;


export const FirstYearPreferencesSettingForm = ({ data, eventMutation, isNew = false }) => {      

  const {isLoading } = eventMutation; 
  const [type, setType] = React.useState(data?.type?.id);  
  const editor = React.createRef();
  const textarea = React.createRef();
  const getHtml = () => {
    if (editor.current && textarea.current) {
      const view = editor.current.view;
      if (view) {
        textarea.current.value = EditorUtils.getHtml(view.state);
      }
    }
  };
  const setHtml = () => {
    if (editor.current) {
      const view = editor.current.view;
      if (view && textarea.current) {
        EditorUtils.setHtml(view, textarea.current.value);
      }
    }
  };
  const fieldChange = (e) =>
  {        
    //debugger;
    const fieldName = e.target?.name;    
    if(fieldName === "type")
    setType(e.value.id);
    if(eventMutation.error?.response?.data == null)        
    return;
    delete eventMutation.error.response.data[fieldName];    
  }  
  
  return (
    <>        
    <Form
      onSubmit={(d) => 
        {

          debugger;

          //var a = EditorUtils.getHtml(editor.current.view)
          
          return eventMutation.mutate(
            {...d , 
              //visibleInfo: EditorUtils.getHtml(d.visibleInfo),
              //visibleInfo: EditorUtils.getHtml(editor.current.view.state),
              availabilityStarts: (DateTime.fromJSDate(d.availabilityStarts)).toISO(), 
              availabilityEnds: (DateTime.fromJSDate(d.availabilityEnds)).toISO(), 
            })
        }}      
      initialValues={data}                        
      render={(formRenderProps) => (
        <FormElement
          // style={{
          //   maxWidth: '100%',
          // }}
        >
          {/* <p> hi {data?.triggeredBy}</p> */}
          <fieldset className={"k-form-fieldset"}>
            <legend className={"k-form-legend"}>
              Please fill in the following information:
            </legend>
            {eventMutation.isError && <ValidationSummary error={eventMutation.error} />}
            {formRenderProps.visited &&
              formRenderProps.errors &&
              formRenderProps.errors.VALIDATION_SUMMARY && (
                <div className={"k-messagebox k-messagebox-error"}>
                  {formRenderProps.errors.VALIDATION_SUMMARY}
                </div>
              )}                                                                             
            <div className="mb-3">
              <Field name={"currentTermId"} 
              component={Input} 
              onChange={fieldChange}                     
              //validator={(d) => fieldValidator(d,"notes", triggerEventMutation.error, requiredValidator)} 
              label={"Current Term Id"} />
            </div>  
            <div className="mb-3">
              <Field name={"minNumberOfRequiredElectives"} 
              component={NumericTextBox} 
              onChange={fieldChange}                     
              //validator={(d) => fieldValidator(d,"notes", triggerEventMutation.error, requiredValidator)} 
              label={"Min Number Of Required Electives"} />
            </div>  
            <div className="mb-3">
            <Field                
                name={"availabilityStarts"}
                label={"Availability Starts"}
                hintDirection={"end"}                
                component={FormDatePicker}
                onChange={fieldChange}
                //disabled = {!isNew}
                //validator={(d) => fieldValidator(d,"triggerAt", triggerEventMutation.error)} 
                format="dd-MMM-yyyy HH:mm Z"
              />
            </div>      
            <div className="mb-3">
            <Field                
                name={"availabilityEnds"}
                label={"Availability Ends"}
                hintDirection={"end"}                
                component={FormDatePicker}
                onChange={fieldChange}
                //disabled = {!isNew}
                //validator={(d) => fieldValidator(d,"triggerAt", triggerEventMutation.error)} 
                format="dd-MMM-yyyy HH:mm Z"
              />
            </div>               
            <div>Internal Notes:</div>
            <div className="mb-3">
              <Field name={"notes"} 
              component={TextArea} 
              onChange={fieldChange}                     
              //validator={(d) => fieldValidator(d,"notes", triggerEventMutation.error, requiredValidator)} 
              label={" Notes"} />
            </div>   
            {/* <div>Visible Info:</div> */}
            {/* <div className="mb-3">
              <Field 
              name={"visibleInfo"} 
              // value={data?.visibleInfo?.html}
              component={Editor} 
              //defaultContent={"<p>editor sample html</p>"}
              tools={[[Bold, Italic, Underline]]}
              //ref={editor}
              //onChange={fieldChange}                     
              //validator={(d) => fieldValidator(d,"notes", triggerEventMutation.error, requiredValidator)} 
              label={" Visible Info"} />
            </div>           */}
            {/* <Editor
                tools={[
                  [Bold, Italic, Underline, Strikethrough],
                  [Subscript, Superscript],
                  ForeColor,
                  BackColor,
                  [CleanFormatting],
                  [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                  [Indent, Outdent],
                  [OrderedList, UnorderedList],
                  [NumberedList, BulletedList],
                  FontSize,
                  FontName,
                  FormatBlock,
                  [SelectAll],
                  [Undo, Redo],
                  [Link, Unlink, InsertImage, ViewHtml],
                  [InsertTable, InsertFile],
                  [Pdf, Print],
                  [TableProperties, TableCellProperties],
                  [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                  [DeleteRow, DeleteColumn, DeleteTable],
                  [MergeCells, SplitCell],
                ]}
                name={"visibleInfo"} 
                //data={ data?.visibleInfo && EditorUtils.setHtml(view, textarea.current.value)}
                defaultContent={data?.visibleInfo}
                onChange={() => formRenderProps.onChange("visibleInfo", { value: EditorUtils.getHtml(editor.current.view.state) })}
                contentStyle={{
                  height: 160,
                }}
                //defaultContent={"<p>editor sample html</p>"}
                ref={editor}    />             */}
          </fieldset>
                    

          <div className="k-form-buttons">
            <button
              type={"submit"}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              disabled={!formRenderProps.allowSubmit || isLoading}
            >              
              Submit
            </button>            
           <PageMutating/>
          </div>
        </FormElement>
      )}
    />
    </>
  );
};

