import React, { Component } from "react";
import { EditorComponent } from "../components/EditorComponent";

export const Home = ({ data }) => {
  const editRoles = ["Manager", "Admin"];
  const editClaims = ["LawNET Development"];

  return (
    <div>
      <EditorComponent
        editorId={"EditorSetting_HomePage"}
        editRoles={editRoles}
        editClaims={editClaims}
      />
    </div>
  );
};
