import React from "react";
import ApiStatus from "./ApiStatus";
import { useFetchSetting } from "../services/settingsService";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

export const VersionChecker = (props) => {
  const { data, status, isSuccess, isError, error } = useFetchSetting("RecordsWebsiteSetting");
  const [visible, setVisible] = React.useState(true);
  const toggleDialog = () => {
    setVisible(!visible);
  };
  
  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <></>;

  const IsHardRefreshNeeded = (version) => {    
    const v1Parts = process.env.REACT_APP_VERSION.split('.').map(Number);    
    const v2Parts = version.split('.').map(Number);

    for (let i = 0; i < v1Parts.length; i++) {
      if (v1Parts[i] < v2Parts[i]) return true;
      if (v1Parts[i] > v2Parts[i]) return false;
    }

    return false;    
  }

  return (
    <div className="version-dialog">
        <div>    
        {visible && IsHardRefreshNeeded(data?.minFrontEndVersion) && (
        <Dialog title={"Important"} onClose={toggleDialog} closeIcon="">
          <div
            style={{
              margin: "25px",
              padding: "0px 0 100px 0px",
              textAlign: "center",
            }}
          >
            To ensure you're using the latest version of the app, please perform a hard refresh (Ctrl + Shift + R on Windows or Command + Shift + R on Mac)
          </div>
          <DialogActionsBar>         
            <Button type="button" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid" onClick={() => window.location.reload()}>
              Reload
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>     
    <style>
      {`               
      
      `}
    </style>
    </div>
  );
};
