import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { getter,SvgIcon } from "@progress/kendo-react-common";
import { DateInput } from "@progress/kendo-react-dateinputs";
import ValidationSummary from "../../../components/ValidationSummary";
import { getValue } from "@testing-library/user-event/dist/utils";
import ApiStatus from "../../../components/ApiStatus";
import { PageLoader } from "../../../components/PageLoader";
import { PageMutating } from "../../../components/PageMutating";
import { DateTime, Settings } from "luxon";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { ListView } from "@progress/kendo-react-listview";
import { ListBox, ListBoxToolbar, processListBoxData,processListBoxDragAndDrop } from "@progress/kendo-react-listbox";
import {
  saveIcon,
  calculatorIcon,
} from "@progress/kendo-svg-icons";
import TinyQueue from 'tinyqueue';


const ValidatedInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const payloadGetter = getter("payload");

// const triggerEventFormValidator = (values, isError, error) => {   
//   if (payloadGetter(values)) {
//     return;
//   }  
//   return {
//     VALIDATION_SUMMARY: "Please fill at least one of the following fields.",
//     ["payload"]:
//       "Please check the validation summary for more information.",    
//   };
// };

const CourseItem = (props) => {  
  let { dataItem, selected, ...others } = props;
  let item = props.dataItem;
 const formatToHourMinute = (dateTime) => {
    const date = new Date(dateTime); // Convert to Date object
  
    // Get hours and minutes using getter methods
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    // Return formatted string (HH:MM)
    return `${hours}:${minutes}`;
  }

  return (
    <li {...others} key={`course_${item.srsNumber}`}>
      <div
      className="k-listview-item"
      style={{
        padding: 10,
        borderBottom: "1px solid lightgrey",
      }}
    >
      <b>{item.termId} - {item.courseNum}, {item.section}</b> ({item.srsNumber}) {item.rankingRequired && '*' }  <br />
      {item.title} with Prof. {item.instructorFirstName} {item.instructorLastName} ({item.units}) units  <br />     

      {item?.classMeetings?.map((a) => { 
        return <div key={a.sequenceNumber}><span key={a.sequenceNumber}> {a.daysOfWeek} {formatToHourMinute(a.classStartTime)} - { formatToHourMinute(a.classStopTime)} </span><br/></div>
      })}      
      {item.countEnrolled}/{item.capacity} capacity,        
       <span> Student Preference: </span> <span className={item.curStudentPreference > 0 ? "text-primary" : ""}> <b> {item.curStudentPreference > 0 ? item.curStudentPreference : "-"} </b></span>, Is Enrolled? <b><span style={{margin:'0px 5px'}} className={"k-icon k-font-icon " + (item.isEnrolled ? "k-i-checkbox-checked text-primary" :  "k-i-checkbox" )}></span></b> 
      <br />
      {/* <div className="col-4">
        <div className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base" onClick={(e) => props.onItemClick(e)}>
          <div className="k-chip-content">{item.messages} Enrollments</div>
        </div>
      </div> */}
    </div>
    </li>    
  );
};

const StudentItem = (props) => {
  let { dataItem, selected, ...others } = props;
  let item = props.dataItem;
  return (
    <li {...others}>
      <div
            className="k-listview-item"
            style={{
              padding: 10,
              borderBottom: "1px solid lightgrey",
            }}
          >            
            <b>{item.lastName}, {item.firstName}</b> - {item.employeeId}<br />
            <span className={item.enrolledUnits > 8 ? "text-danger" : ""}><b>{item.enrolledUnits}</b> units enrolled, Happiness Score: {(item.happinessScore * 100 )?.toFixed(2) }% <br /></span> 
            {item.doesScheduledConflict && <span className="scheduleConflict"> <span className={"k-icon k-font-icon " + (item.doesScheduledConflict ? "k-i-x-outline" :  "" )}></span> Schedule Conflict  </span>} 
            
            <br />
            {/* <button onClick={alert('test')}>Test</button> */}
          </div>
    </li>    
  );
};

const ChangeLog = (props) => {
  let { dataItem, ...others } = props;
  let item = props.dataItem;
  return (    
      <div
          className="k-listview-item small"
          style={{
            padding: 10,
            borderBottom: "1px solid lightgrey",
      }}
        >            
           {item?.id} <b>{item?.lastName}, {item?.firstName}</b> - {item?.employeeId} {item?.action}  {item?.action === "Unenrolled" ? "from" : "to"} <b>{item?.srsNumber}</b> course<br />
        </div>    
  );
};

const SELECTED_FIELD = "selected";

const checkScheduledConflict= (student, course, coursesDic) =>{ 
   const hasMatchDay = (daysString1, daysString2) => {
    const charSet = new Set(daysString2.split(""));
    // Loop through each character in the first string
    for (const char of daysString1) {
      // Check if the character exists in the Set
      if (charSet.has(char)) {
        return true;
      }
    }
    // If the loop completes without finding a match, return false
    return false;
  }
  
  if(student.firstYearEnrollments === undefined)
    return false;
  for (const enrolledCourseSrsNumber of student.firstYearEnrollments) {
    if(enrolledCourseSrsNumber === course.id)
      continue;    
    if(coursesDic[enrolledCourseSrsNumber].classMeetings.some(a => course.classMeetings.some(b => 
      hasMatchDay(a.daysOfWeek, b.daysOfWeek)
      && a.classStartTime <= b.classStopTime 
      && a.classStopTime >= b.classStartTime)))
     return true;
  }
  return false;
}

const studentSortComparer = (s1, s2) => {
  var lastName1 = s1.lastName;
  var lastName2 = s2.lastName;
  if (lastName1 !== lastName2) {
    return lastName1.localeCompare(lastName2);
  }
  var firstName1 = s1.firstName;
  var firstName2 = s2.firstName;
  // If last names are the same, sort by first name
  return firstName1.localeCompare(firstName2);
}

export const EnrollmentToolForm = ({ data, eventMutation: eventMutation, isNew = false }) => {      
 // debugger;
  const [state, setState] = React.useState({        
    // electiveCourseOptions: initElectiveCourseOptions(data),    
    // electivePreferences: data.electivePreferences,
    // criminalLawPreferences: initCriminalLawPreferences(data),
    // propertyLawPreferences: initPropertyLawPreferences(data),        
    students: data.students,    
    enrollments: data.enrollments ?? [],
    selectedCourseStudents: data.students?.filter(st => !data.enrollments[data?.courses[0]?.id]?.some(a => a.id === st.id)),
    selectedCourseEnrollments: data.students?.filter(st => data.enrollments[data?.courses[0].id]?.some(a => a.id === st.id)),
    studentIdToStudent: data.students.reduce((stu, item) => {
      stu[item.id] = item; // Use item.id as the key in the dictionary
      return stu;
    }, {}),
    courses: data.courses.map((c , index) => {
      if(index === 0) 
        c[SELECTED_FIELD] = true;
      return {...c};
    }),
    selectedCourse: data.courses[0],
    selectedStudent: {},
    draggedItem: {},
    changeLogs: [],
  });
  
  const {isLoading } = eventMutation; 

  const handleCourseClick = (event) => {   
    
    const studentsDic = state.students.reduce((stu, item) => {
      stu[item.id] = item; // Use item.id as the key in the dictionary
      //item[SELECTED_FIELD] = false;
      return stu;
    }, {});

    const selectedCourse = state.courses.find(a => a.id === event.dataItem.id);
    const coursesDic = state.courses.reduce((course, item) => {
      course[item.id] = item; // Use item.id as the key in the dictionary
      return course;
    }, {}); 

    setState({
      ...state,
      //students: state.students.map(s => s),
      courses: state.courses.map((item, index) => {      
        item[SELECTED_FIELD] = item.id === event.dataItem.id;        
        //item.isEnrolled = false;       
        //item.curStudentPreference = 0;
        return item;
      }),
      selectedCourseEnrollments:  state.enrollments[event.dataItem.id]?.map(a => {
          return { ...studentsDic[a.id], 
              doesScheduledConflict : checkScheduledConflict(studentsDic[a.id],selectedCourse, coursesDic),
              [SELECTED_FIELD] : state.selectedStudent?.id === a.id, 
            };
          //return state.students.find(s => s.id === a.id) ?? { ...a, [SELECTED_FIELD] : false}           
        }) ?? [],      
      selectedCourseStudents: state.students.filter(s => !state.enrollments[event.dataItem.id]
        ?.some(st => st.id === s.id))
        .map(a => { 
          return { ...a, 
            [SELECTED_FIELD] : state.selectedStudent?.id === a.id, 
            doesScheduledConflict : checkScheduledConflict(a, selectedCourse, coursesDic)}
      }),
      selectedCourse:selectedCourse,
    });
  }

  const handleStudentItemClick = (event, data, connectedData) => {    
    //state.students
    //debugger;
    const students = state.students.reduce((stu, item) => {
      stu[item.id] = item; // Use item.id as the key in the dictionary
      //item[SELECTED_FIELD] = false;
      return stu;
    }, {});
    var isSelected = false;
    setState({
      ...state,
      courses : state.courses.map((item, index) => {      
        item.isEnrolled = state.enrollments[item.id]?.some(a => a.id === event.dataItem.id);        
        item.curStudentPreference = students[event.dataItem.id]?.coursePreferences?.[item.id]?.order ?? 0;
        return item;
      }),
      [data]: state[data].map((item, index) => {
        if (item.id === event.dataItem.id) {
          item[SELECTED_FIELD] = true; // / !item[SELECTED_FIELD];
          isSelected = item[SELECTED_FIELD];
        } else //if (!event.nativeEvent.ctrlKey) 
         {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: state[connectedData].map((item, index) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
      selectedStudent: isSelected ? students[event.dataItem.id] : {},
    });
  };

  const calculateHappinessScoreDifference = (student) => {
    if(student?.createdFirstYearPreferences === undefined)
      return 0;
    if(student?.createdFirstYearPreferences?.length === 0)
      return  0;
    if(student?.createdFirstYearPreferences[0]?.coursePreferences?.find(a => a.srsNumber === state.selectedCourse.id) === undefined)
      return 0;
    return  (12 + 1 - (student?.createdFirstYearPreferences[0]?.coursePreferences?.find(a => a.srsNumber === state.selectedCourse.id)?.order ?? 0)) / (12 + 11) 
  }

  const handleToolBarClick = (e, formRenderProps) => {
    const selectedCourseId = state.selectedCourse.id;
    let toolName = e.toolName || "";
    let result = processListBoxData(
      state.selectedCourseEnrollments,
      state.selectedCourseStudents,      
      toolName,
      SELECTED_FIELD
    );      

    var selectedStudent = undefined;
    if(toolName === "transferTo"){      
      selectedStudent = state.selectedCourseEnrollments.filter(a => a[SELECTED_FIELD] === true);            
      result.listBoxTwoData = result.listBoxTwoData.map((student)  => { 
        if(student.id === selectedStudent[0].id)
        {
          const st = {...student};
          st.enrolledUnits = student.enrolledUnits - state.selectedCourse.units;          
          st.firstYearEnrollments.delete(state.selectedCourse.srsNumber);
          st.happinessScore -= calculateHappinessScoreDifference(st);
          st.countEnrolled= student.countEnrolled-1
          return st;
        }
        return student;  
      });
    }

    //console.log(state.selectedCourse.units)

    

    if(toolName === "transferFrom"){
      selectedStudent = state.selectedCourseStudents.filter(a => a[SELECTED_FIELD] === true);      
      result.listBoxOneData = result.listBoxOneData.map((student)  => { 
        if(student.id === selectedStudent[0].id)
        {
          const st = {...student};
          st.enrolledUnits = student.enrolledUnits + state.selectedCourse.units;          
          st.firstYearEnrollments.add(state.selectedCourse.srsNumber);
          st.countEnrolled= student.countEnrolled+1;          
          st.happinessScore += calculateHappinessScoreDifference(st);
          return st;
        }
        return student;        
      });
    }

    if(selectedStudent !== undefined)
    {            
      debugger;
      state.students = state.students.map((student, index) => {
        if (student.id === selectedStudent[0].id)
        {          
            return {...student, 
              countEnrolled:  toolName === "transferFrom" ? student.countEnrolled + 1 : student.countEnrolled - 1,
              enrolledUnits: toolName === "transferFrom" ? student.enrolledUnits + state.selectedCourse.units 
                                                         : student.enrolledUnits - state.selectedCourse.units, 

              happinessScore: toolName === "transferFrom" ? student.happinessScore + calculateHappinessScoreDifference(student) 
                                                          : student.happinessScore - calculateHappinessScoreDifference(student),
            }            
        }          
        return {...student};
      });    
      
      state.changeLogs.unshift({
        ...selectedStudent[0],
        action: toolName === "transferFrom" ? "Enrolled" : "Unenrolled",
        srsNumber: state.selectedCourse.id,
        id:state.changeLogs.length + 1
      })
    }        
    const selectedCourseEnrollments = result.listBoxOneData.sort(studentSortComparer)
    setState({
      ...state,
      selectedCourseEnrollments : selectedCourseEnrollments,
      selectedCourseStudents: result.listBoxTwoData.sort(studentSortComparer) ,
      enrollments: { ...state.enrollments, [selectedCourseId]: selectedCourseEnrollments},      
      students: state.students,
      changeLogs: state.changeLogs,
      courses: state.courses.map((item, index) => {      
        //if(toolName === "transferFrom")
        if(item.id === selectedCourseId){
          if(toolName === "transferTo"){
            item.isEnrolled =false;  
            item.countEnrolled= item.countEnrolled - 1
          }
          if(toolName === "transferFrom"){
            item.isEnrolled =true;  
            item.countEnrolled= item.countEnrolled + 1
          }          
        }
        return item;
      }),      
    });

    // formRenderProps.onChange("students", { value: result.listBoxOneData.map(a => a)});  
    formRenderProps.onChange("enrollments", { value: {...state.enrollments, [selectedCourseId]: result.listBoxOneData}});
  };

  const handleDragStart = (e) => {
    setState({
      ...state,
      draggedItem: e.dataItem,
    });
  };

  const getEnrollId = (srsNumber, studentId) => srsNumber + "-" + studentId;

  const runAutoEnroll = (e, formRenderProps) => {    
    e.preventDefault();
    // course enrolled set : srsNumber-studentId as key
    //debugger;
    const courseEnrolledSet = new Set();
    for (const [srsNumber, students] of Object.entries(state.enrollments)) {
      students?.map(
        student => courseEnrolledSet.add(getEnrollId(srsNumber, student.id))
      )      
    }
    
    // state.courses  is array
    const coursesDic = state.courses.reduce((course, item) => {
      course[item.id] = item; // Use item.id as the key in the dictionary
      return course;
    }, {}); 

    // state.students is array
    const studentsDic = state.students.reduce((stu, item) => {
      stu[item.id] = item; // Use item.id as the key in the dictionary
      return stu;
    }, {});

    // state.enrollments is dictionary

    state.students.map(stu =>
    {
      stu.createdFirstYearPreferences?.forEach(pref => {
        pref.electivePreferences?.forEach(elective => {          
          if(!courseEnrolledSet.has(elective.srsNumber + "-" + stu.id))
          {            
            coursesDic[elective.srsNumber].popularity += 1;           
            coursesDic[elective.srsNumber].interesetedStudents.push({ id : stu.id, order: elective.order});
          }              
        })       
     });    
    });

    const electiveCourseOrderdByPopularity = state.courses.filter(a => a.popularity > 0
        // && a.category === "Elective"
        ).sort((a, b) => b.popularity - a.popularity);

    var countStundentsEnrolled = 0;
    var currUnits = 3;
    var queue = [];
    electiveCourseOrderdByPopularity.forEach(course => {
      const interesetedStudents = coursesDic[course.id].interesetedStudents.sort((a, b) => a.order - b.order);
      //debugger;
      interesetedStudents.forEach(stu => {
          const enrolid = getEnrollId(course.id, stu.id)
          if(enrolid in courseEnrolledSet)
            return;
          if(course.countEnrolled >= course.capacity)
            return;               
          if(studentsDic[stu.id].enrolledUnits + course.units >  8)
            return;
          // check for schedule conflict 
          if(checkScheduledConflict(studentsDic[stu.id], course, coursesDic))                      
            return;          
            
          // enroll the student
          if(!(course.id in state.enrollments))
            state.enrollments[course.id] = [];
          currUnits += countStundentsEnrolled % state.students.Length === 0 ? 3: 0;
          if(studentsDic[stu.id].enrolledUnits >= currUnits)
          {
              queue.push({course: course, student: stu});
          }
          
          countStundentsEnrolled++;                    
          state.enrollments[course.id].push(studentsDic[stu.id]);
          // update the student enrolled units
          studentsDic[stu.id].enrolledUnits += course.units;          
          // update the course enrolled count
          course.countEnrolled += 1;
          // update the course popularity
          //course.popularity -= 1;
          courseEnrolledSet.add(enrolid)               
          studentsDic[stu.id].firstYearEnrollments.add(course.srsNumber);
          // update the student first year enrollments
      });      
    })
    
    //debugger;    
        
    const selectedCourseEnrollments = state.enrollments[state.selectedCourse.id]?.map(a => {
      return { ...studentsDic[a.id], 
          doesScheduledConflict : checkScheduledConflict(studentsDic[a.id],state.selectedCourse, coursesDic),
          [SELECTED_FIELD] : state.selectedStudent?.id === a.id, 
        };
      //return state.students.find(s => s.id === a.id) ?? { ...a, [SELECTED_FIELD] : false}           
    }).sort(studentSortComparer) ?? [];


    const selectedCourseStudents = state.students.filter(s => !state.enrollments[state.selectedCourse.id]
      ?.some(st => st.id === s.id))
      .map(a => { 
        return { ...a, 
          [SELECTED_FIELD] : state.selectedStudent?.id === a.id, 
          doesScheduledConflict : checkScheduledConflict(a, state.selectedCourse, coursesDic)}
    });           

    setState({
      ...state,      
      courses: Object.values(coursesDic),
      selectedCourseEnrollments:  selectedCourseEnrollments,      
      selectedCourseStudents: selectedCourseStudents,
      enrollments: { ...state.enrollments },             
    });

    formRenderProps.onChange("enrollments", { value: {...state.enrollments}});    
    
    return false;
  };

  const runAutoEnrollUsingHappinessScore = (e, formRenderProps) => {    
    e.preventDefault();
    // course enrolled set : srsNumber-studentId as key
    debugger;
    

    const courseEnrolledSet = new Set();
    for (const [srsNumber, students] of Object.entries(state.enrollments)) {
      students?.map(
        student => courseEnrolledSet.add(getEnrollId(srsNumber, student.id))
      )      
    }
    
    // state.courses  is array
    const coursesDic = state.courses.reduce((course, item) => {
      course[item.id] = item; // Use item.id as the key in the dictionary
      return course;
    }, {}); 

    // state.students is array
    const studentsDic = state.students.reduce((stu, item) => {
      stu[item.id] = item; // Use item.id as the key in the dictionary
      return stu;
    }, {});

    // state.enrollments is dictionary

    // state.students.map(stu =>
    // {
    //   stu.createdFirstYearPreferences?.forEach(pref => {
    //     pref.electivePreferences?.forEach(elective => {          
    //       if(!courseEnrolledSet.has(elective.srsNumber + "-" + stu.id))
    //       {            
    //         coursesDic[elective.srsNumber].popularity += 1;           
    //         coursesDic[elective.srsNumber].interesetedStudents.push({ id : stu.id, order: elective.order});
    //       }              
    //     })       
    //  });    
    // });

    const studentsQueue = new TinyQueue(state.students.filter(s => s.createdFirstYearPreferences?.length > 0), (a, b) => a.happinessScore - b.happinessScore); // Min-heap by default
    //const studentQueue = state.students.sort((a, b) => b.happinessRanking - a.happinessRanking);

    var countStundentsEnrolled = 0;    
    var maxEnrollCourses = 2;
    var maxScore = 12 + 11;
    while(studentsQueue.length > 0)
    {
      var student = studentsQueue.pop();                         
      if(studentsDic[student.id].countEnrolled < maxEnrollCourses)
        {
          for (let i = 0; i < student.createdFirstYearPreferences[0].coursePreferences.length; i++)
          {
            let coursePref = student.createdFirstYearPreferences[0].coursePreferences[i];
            const course = coursesDic[coursePref.srsNumber];
            const enrolid = getEnrollId(coursePref.srsNumber, student.id);
            
    
            if(courseEnrolledSet.has(enrolid))
            {
              continue;
            }              
            if(course.countEnrolled >= course.capacity)
              continue;             
            // if(studentsDic[student.id].enrolledUnits + course.units >  8)
            //   return;            
            if(checkScheduledConflict(studentsDic[student.id], course, coursesDic))                      
              continue;  
    
            if(!(coursePref.srsNumber in state.enrollments))
              state.enrollments[coursePref.srsNumber] = [];
            //currUnits += countStundentsEnrolled % state.students.Length === 0 ? 3: 0;
                                
            state.enrollments[coursePref.srsNumber].push(studentsDic[student.id]);
            // update the student enrolled units
            studentsDic[student.id].enrolledUnits += course.units;          
            // update the course enrolled count
            course.countEnrolled += 1;            
            courseEnrolledSet.add(enrolid)               
            studentsDic[student.id].firstYearEnrollments.add(coursePref.srsNumber);    
            student.happinessScore += (12 + 1 - coursePref.order) / maxScore;                                
            break;
          }    
          //studentsQueue.push({course: course, student: student});
          studentsDic[student.id].countEnrolled+=1;
          studentsQueue.push(student);          
        }
      //student.coursePreferences
    }

    // studentQueue.forEach(course => {
    //   const interesetedStudents = coursesDic[course.id].interesetedStudents.sort((a, b) => a.order - b.order);
    //   //debugger;
    //   interesetedStudents.forEach(stu => {
    //       const enrolid = getEnrollId(course.id, stu.id)
    //       if(enrolid in courseEnrolledSet)
    //         return;
    //       if(course.countEnrolled >= course.capacity)
    //         return;               
    //       if(studentsDic[stu.id].enrolledUnits + course.units >  8)
    //         return;
    //       // check for schedule conflict 
    //       if(checkScheduledConflict(studentsDic[stu.id], course, coursesDic))                      
    //         return;          
            
    //       // enroll the student
    //       if(!(course.id in state.enrollments))
    //         state.enrollments[course.id] = [];
    //       currUnits += countStundentsEnrolled % state.students.Length === 0 ? 3: 0;
    //       if(studentsDic[stu.id].enrolledUnits >= currUnits)
    //       {
    //           queue.push({course: course, student: stu});
    //       }
          
    //       countStundentsEnrolled++;                    
    //       state.enrollments[course.id].push(studentsDic[stu.id]);
    //       // update the student enrolled units
    //       studentsDic[stu.id].enrolledUnits += course.units;          
    //       // update the course enrolled count
    //       course.countEnrolled += 1;
    //       // update the course popularity
    //       //course.popularity -= 1;
    //       courseEnrolledSet.add(enrolid)               
    //       studentsDic[stu.id].firstYearEnrollments.add(course.srsNumber);
    //       // update the student first year enrollments
    //   });      
    // })
    
    //debugger;    
        
    const selectedCourseEnrollments = state.enrollments[state.selectedCourse.id]?.map(a => {
      return { ...studentsDic[a.id], 
          doesScheduledConflict : checkScheduledConflict(studentsDic[a.id],state.selectedCourse, coursesDic),
          [SELECTED_FIELD] : state.selectedStudent?.id === a.id, 
        };
      //return state.students.find(s => s.id === a.id) ?? { ...a, [SELECTED_FIELD] : false}           
    }).sort(studentSortComparer) ?? [];


    const selectedCourseStudents = state.students.filter(s => !state.enrollments[state.selectedCourse.id]
      ?.some(st => st.id === s.id))
      .map(a => { 
        return { ...a, 
          [SELECTED_FIELD] : state.selectedStudent?.id === a.id, 
          doesScheduledConflict : checkScheduledConflict(a, state.selectedCourse, coursesDic)}
    });           

    setState({
      ...state,      
      courses: Object.values(coursesDic),
      selectedCourseEnrollments:  selectedCourseEnrollments,      
      selectedCourseStudents: selectedCourseStudents,
      enrollments: { ...state.enrollments },             
    });

    formRenderProps.onChange("enrollments", { value: {...state.enrollments}});    
    
    return false;
  };
  
  // const handleDrop = (e, formRenderProps) => {
  //   let result = processListBoxDragAndDrop(
  //     state.electiveCourseOptions,
  //     state.electivePreferences,
  //     state.draggedItem,
  //     e.dataItem,
  //     "id"
  //   );

  //   result.listBoxTwoData = result.listBoxTwoData.map((item, index)  => { return {...item, order: index + 1} });
  //   result.listBoxOneData = result.listBoxOneData.map((item, index)  => { return {...item, order: 0} });

  //   setState({
  //     ...state,
  //     electiveCourseOptions: result.listBoxOneData,
  //     electivePreferences: result.listBoxTwoData,
  //   });    
  //   //debugger;    
  //   formRenderProps.onChange("electiveCourseOptions", { value: result.listBoxOneData.map(a => a)});  
  //   formRenderProps.onChange("electivePreferences", { value: result.listBoxTwoData.map(a => a)});
  // };  

  //console.log(data);
  
  return (
    <>        
    <Form
      onSubmit={(d) => 
        {          
          return eventMutation.mutate( 
            {                           
              enrollments:  Object.fromEntries(
                Object.entries(d.enrollments).map(([key, value]) => [key, value.map(a => a.id)])
            ),            
      })}}
      //onSubmit={(d) => console.log(d)}
      //validator={(v) => triggerEventFormValidator(v,triggerEventMutation.isError, triggerEventMutation.error) }
      initialValues={state}                        
      render={(formRenderProps) => (
        <FormElement
          style={{
            // maxWidth: 650,
          }}
        >
          {/* <p> hi {data?.triggeredBy}</p> */}
          <fieldset className={"k-form-fieldset"}>
            {/* <legend className={"k-form-legend"}>
              Please fill in the following information:
            </legend> */}
            {eventMutation.isError && <ValidationSummary error={eventMutation.error} />}
            {formRenderProps.visited &&
              formRenderProps.errors &&
              formRenderProps.errors.VALIDATION_SUMMARY && (
                <div className={"k-messagebox k-messagebox-error"}>
                  {formRenderProps.errors.VALIDATION_SUMMARY}
                </div>
              )}                  
              <div className="row">
                <div className="col" id="courseListBox">
                    <h6>Courses</h6>    
                    <ListBox
                      data={state.courses}
                      item={CourseItem}   
                      selectedField={SELECTED_FIELD}
                      onItemClick={(e) => 
                        handleCourseClick(e)
                      }                      
                      style={{
                        width: "100%",
                        height: 600,
                      }}
                    />
                  </div>
                  <div className="col studentListBox" id="enrollmentsListBoxContainer"> 
                        <h6>Enrollments for <b>{ state.selectedCourse?.courseNum }, { state.selectedCourse?.section } - { state.selectedCourse?.srsNumber } </b>  ({ state.selectedCourseEnrollments?.length })</h6>    
                        <Field
                          key={"selectedCourseEnrollments"}
                          id={"selectedCourseEnrollments"}
                          name={"selectedCourseEnrollments"}                
                          textField="name"                                
                          component={ListBox}          
                          //onDragStart={handleDragStart}
                          // onDrop={                     
                          //   (e) => handleDrop(e, formRenderProps)                                      
                          // }
                          data={ state.selectedCourseEnrollments }
                          selectedField={SELECTED_FIELD}
                          onItemClick={(e) => 
                            handleStudentItemClick(e, "selectedCourseEnrollments", "selectedCourseStudents")
                          }                          
                          item={StudentItem}     
                          style={{
                            width: "100%",
                            height: 600,
                          }}          
                        />      
                  </div>      
                  <div className="col studentListBox">                     
                    <h6> Students ({ state.selectedCourseStudents?.length }) </h6>                      
                    <Field
                      key={"selectedCourseStudents"}
                      id={"selectedCourseStudents"}
                      name={"selectedCourseStudents"}                                    
                      component={ListBox}          
                      data={ state.selectedCourseStudents ?? []}
                      selectedField={SELECTED_FIELD}
                      onItemClick={(e) =>                                            
                          handleStudentItemClick(e, "selectedCourseStudents", "selectedCourseEnrollments")                       
                      }
                     
                      onDragStart={handleDragStart}
                      // onDrop={                     
                      //   (e) => handleDrop(e, formRenderProps)                                        
                      // }
                      item={StudentItem}
                      toolbar={() => {
                        return (
                          <div style={{ margin: 5 }}> 
                            <ListBoxToolbar                      
                              tools={[
                                // "moveUp",
                                // "moveDown",
                                "transferTo",
                                "transferFrom",
                                //"transferAllTo",
                                //"transferAllFrom",
                                //"remove",
                              ]}
                              
                              data={state.selectedCourseEnrollments }
                              dataConnected={state.selectedCourseStudents}
                              onToolClick={ (e) => 
                                  handleToolBarClick(e, formRenderProps)                            
                              }
                            />
                          </div>
                        );
                      }}   
                      style={{                        
                        width: "100%",
                        height: 600,
                      }}                  
                        //validator={emailValidator}
                      />  
            </div>  
              </div>
              

            {/* <div>Notes:</div>
            <div className="mb-3">
              <Field name={"notes"} 
              component={TextArea} 
              onChange={fieldChange}                     
              //validator={(d) => fieldValidator(d,"notes", triggerEventMutation.error, requiredValidator)} 
              label={" Notes"} />
            </div>                  */}
          </fieldset>
          <div className="row">                                
            <div className="col">
                <div className="k-form-buttons">
                    {/* <button
                      type={"submit"}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      disabled={!formRenderProps.allowSubmit || isLoading}
                    >              
                      Save
                    </button>             */}
                    
                    <div>
                        <Button svgIcon={saveIcon} themeColor={"primary"} disabled={!formRenderProps.allowSubmit || isLoading}>
                          Save
                        </Button>
                    </div>
                    <div style={{marginLeft:'50px'}}>
                      <Button svgIcon={calculatorIcon} onClick={(e)=>runAutoEnrollUsingHappinessScore(e, formRenderProps)} themeColor={"success"} disabled={isLoading}>
                        Run Auto Enroll Algorithm
                      </Button>
                    </div>                          
                    
                  <PageMutating/>
                  </div>
            </div>
            <div className="col">
                  <div id="changeLogsContianer">
                    {/* <h6>Change Log</h6> */}
                    <div>
                      <ListView                        
                        data={state.changeLogs}
                        item={ChangeLog}                                                
                        style={{
                          width: "100%",
                          height: 100,
                        }}
                      />
                    </div>
                  </div>
              </div>
         </div>
          <style>{`      
              #changeLogsContianer{
                margin-top: 20px;
              }        
              .k-listbox{
                width:100%;
              }
              .studentListBox .k-list-item.k-selected, .k-selected.k-list-optionlabel{
                background-color:#4bb4d1;
              }
              #courseListBox .k-list-item.k-selected, .k-selected.k-list-optionlabel{
                background-color:#888888c9;
              }
              
              h6{
                text-align: center;
              }
              .scheduleConflict{
                color: rgb(255 228 146)
              }
              #enrollmentsListBoxContainer .scheduleConflict{
                color: rgb(220 53 69);
              }
              
              `}
        </style>
        </FormElement>
      )}
    />
    </>
  );
};

