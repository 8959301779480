// import { user } from "../utils/User";
import { handleResponse, handleError } from "./apiUtils";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  InteractionType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { recordsApiConfig } from "../authConfig";
import { useNotification } from "../components/notificationContext";
import { v4 as uuidv4 } from "uuid";

export const useFetchPublic = (
  { url, query, dataMapper = (a) => a },
  ...args
) => {
  const { notifications, setNotifications } = useNotification();
  const accessTokenRequest = {
    ...recordsApiConfig,
  };

  return useQuery(
    [url, query],
    async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_RECORD_API_URL}${url}${
            query ? `?${query}` : ""
          }`
        );
        //console.log(JSON.parse(atob(accessToken.split(".")[1])));
        // debugger;
        return dataMapper(resp.data);
      } catch (e) {
        // Catch interaction_required errors and call interactive method to resolve
        //debugger;
        var errorText = e?.message;
        if (e?.message === "Network Error")
          errorText = `Network Error, can't connect.`;
        else if (e?.response?.status === 403) {
          errorText =
            "Forbidden access. You don't have permission to see this item.";
        }
        setNotifications([
          ...notifications,
          {
            id: uuidv4(),
            text: errorText,
            style: "error",
          },
        ]);
      } finally {
        // setLoading(false);?
      }
    },
    {
      retry: (failureCount, error) => {
        // Only retry if the status is one of the following statuses
        const retryStatus = [408, 429, 500, 502, 503, 504];
        if (retryStatus.includes(error.response?.status)) {
          return true;
        }
        return false; // You can specify the number of retries as well
      },
    },
    ...args
  );
};

export const useFetch = ({ url, query, dataMapper = (a) => a }, ...args) => {
  const { instance, inProgress, accounts } = useMsal();
  const { notifications, setNotifications } = useNotification();
  const accessTokenRequest = {
    ...recordsApiConfig,
    account: accounts[0],
  };

  return useQuery(
    [url, query],
    async () => {
      try {
        const { accessToken } = await instance.acquireTokenSilent(
          accessTokenRequest
        );
        const resp = await axios.get(
          `${process.env.REACT_APP_RECORD_API_URL}${url}${
            query ? `?${query}` : ""
          }`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        //console.log(JSON.parse(atob(accessToken.split(".")[1])));
        // debugger;
        return dataMapper(resp.data);
      } catch (e) {
        // Catch interaction_required errors and call interactive method to resolve
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(accessTokenRequest);
        }
        if (e.response?.status === 404) return false;
        //debugger;
        var errorText = e?.message;
        if (e?.message === "Network Error")
          errorText = `Network Error, can't connect.`;
        else if (e?.response?.status === 403) {
          errorText =
            "Forbidden access. You don't have permission to see this item.";
        }
        setNotifications([
          ...notifications,
          {
            id: uuidv4(),
            text: errorText,
            style: "error",
          },
        ]);
        handleError(e, instance);
      } finally {
        // setLoading(false);?
      }
    },
    {
      retry: (failureCount, error) => {
        // Only retry if the status is one of the following statuses
        const retryStatus = [408, 429, 500, 502, 503, 504];
        if (retryStatus.includes(error.response?.status)) {
          return true;
        }
        return false; // You can specify the number of retries as well
      },
    },
    ...args
  );
};

export const useFetchWithPost = (
  { url, query, dataMapper = (a) => a },
  ...args
) => {
  const { instance, inProgress, accounts } = useMsal();
  const { notifications, setNotifications } = useNotification();
  const accessTokenRequest = {
    ...recordsApiConfig,
    account: accounts[0],
  };

  return useQuery(
    [url, query],
    async () => {
      try {
        const { accessToken } = await instance.acquireTokenSilent(
          accessTokenRequest
        );
        const resp = await axios.post(
          `${process.env.REACT_APP_RECORD_API_URL}${url}${
            query ? `?${query}` : ""
          }`,
          null,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        //console.log(JSON.parse(atob(accessToken.split(".")[1])));
        // debugger;
        return dataMapper(resp.data);
      } catch (e) {
        // Catch interaction_required errors and call interactive method to resolve
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(accessTokenRequest);
        }
        //debugger;
        var errorText = e?.message;
        if (e?.message === "Network Error")
          errorText = `Network Error, can't connect.`;
        else if (e?.response?.status === 403) {
          errorText =
            "Forbidden access. You don't have permission to see this item.";
        }
        setNotifications([
          ...notifications,
          {
            id: uuidv4(),
            text: errorText,
            style: "error",
          },
        ]);
        handleError(e, instance);
      } finally {
        // setLoading(false);?
      }
    },
    { staleTime: 0 },
    ...args
  );
};
