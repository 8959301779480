import * as React from "react";
import { Form,Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@progress/kendo-react-inputs";
import { TextArea,Input } from "@progress/kendo-react-inputs";
import { ElectivePreference } from "./components/ElectivePreference";
import ValidationSummary from "../../components/ValidationSummary";
import {CriminalLawPreference} from "./components/CriminalLawPreference";
import { PropertyLawPreference } from "./components/PropertyLawPreference";
import {
  FormInput,
  FormAutoComplete,
  FormRadioGroup,
  FormTextArea,
} from "../../components/form-components";
import { ReviewPreferences } from "./components/ReviewPreferences";
import { useFetchSetting } from "../../services/settingsService";

const stepPages = [ElectivePreference, //CriminalLawPreference, PropertyLawPreference, 
    ReviewPreferences];// ,PersonalDetails, PaymentDetails

export const FirstYearPreferenceFormSteps = [
  {
    label: "Elective Preferences",
    isValid: undefined,
  },
  // {
  //   label: "Criminal Law Preferences",
  //   isValid: undefined,
  // },
  // {
  //   label: "Property Law Preferences",
  //   isValid: undefined,
  // },
  {
    label: "Review and Submit",
    isValid: undefined,
  }
];

export const FirstYearPreferenceForm = ({ data, stepsInit, eventMutation, isNew = false }) => {  
  //const [step, setStep] = React.useState(stepId ?? 0);
  const { stepId } = useParams();  
  const step = Number(stepId ? stepId : 0 );  
  const [formState, setFormState] = React.useState( {...data });  
  const [steps, setSteps] = React.useState(stepsInit);
  //const { data: setting, status, isSuccess, isError, error } = useFetchSetting('FirstYearPreferencesSetting');
  const nav = useNavigate();
  

  const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;
  const isPreviousStepsValid =
    steps
      .slice(0, step)
      .findIndex((currentStep) => currentStep.isValid === false) === -1;

  const onStepSubmit = React.useCallback(
    (event) => {
      const { isValid, values } = event;
      const currentSteps = steps.map((currentStep, index) => ({
        ...currentStep,
        isValid: index === step ? isValid : currentStep.isValid,
      }));
      debugger;
      setSteps(currentSteps);

      if (!isValid) {
        return;
      }
      //debugger;
      setFormState({...values });       
      eventMutation.mutate({...values });                   
      
      if (isLastStep && isPreviousStepsValid && isValid) {
        //alert(JSON.stringify(values));
      }
    },
    [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
  );
  const onPrevClick = (e) => {    
    e.preventDefault();
    nav(`/firstyearpreferences/${data.id}/edit&step=${Math.max(step - 1, 0)}`)        
  };    
  
  const currStepPage = stepPages[step](formState, null, null, null, null);

  return (
    <>           
      <div
      style={{
        display: "flex ",
        flexDirection: "column",
        justifyContent: "center",  
      }}
    >
      <Stepper value={step} items={steps} />
      
      <Form
        id={data.id}
        initialValues={formState}
        onSubmitClick={onStepSubmit}
        render={(formRenderProps) => (          
          <div
            style={{
              alignSelf: "center",
            }}
          >
            <FormElement>
            <div style={{minHeight:40, paddingTop:20}}>
              {eventMutation.isError && <ValidationSummary error={eventMutation.error} />}
              {formRenderProps.visited &&
                formRenderProps.errors &&
                formRenderProps.errors.VALIDATION_SUMMARY && (
                  <div className={"k-messagebox k-messagebox-error"}>
                    {formRenderProps.errors.VALIDATION_SUMMARY}?
                  </div>
                )}  
            </div>                                                                                  
                           
              {  currStepPage(formRenderProps)}              

              <span
                style={{
                  marginTop: "40px",
                }}
                className={"k-form-separator"}
              />
              <div
                style={{
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
                className={
                  "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                }
              >
                <span
                  style={{
                    alignSelf: "center",
                  }}
                >
                  Step {step + 1} of {stepPages.length}
                </span>
                <div>
                  {step !== 0 ? (
                    <Button
                      style={{
                        marginRight: "16px",
                      }}
                      onClick={onPrevClick}
                    >
                      Previous
                    </Button>
                  ) : undefined}
                  <Button
                    themeColor={"primary"}                    
                    disabled={isLastStep ? !isPreviousStepsValid || !formRenderProps.allowSubmit || !formRenderProps.valueGetter('confirmedPreferences') : false}
                    onClick={formRenderProps.onSubmit}
                  >                    
                    {isLastStep ? "Submit" : "Next"}
                  </Button>                 
                </div>
              </div>
            </FormElement>
          </div>
        )}
      />
    </div>
    </>
  );
};
