export const initCriminalLawPreferences = (data) => {
  const res = [
    ...data.criminalLawPreferences,
    ...data.courseOptions?.filter(
      (ec) =>
        (ec.category === "CriminalLaw") &
        !new Set(data?.criminalLawPreferences?.map((p) => p.id)).has(ec.id)
    ),
  ];
  res.map((d, i) => (d.order = i + 1));
  data.criminalLawPreferences = res;
  return res;
};

export const initPropertyLawPreferences = (data) => {
  const res = [
    ...data.propertyLawPreferences,
    ...data.courseOptions?.filter(
      (ec) =>
        (ec.category === "PropertyLaw") &
        !new Set(data?.propertyLawPreferences?.map((p) => p.id)).has(ec.id)
    ),
  ];
  res.map((d, i) => (d.order = i + 1));
  data.propertyLawPreferences = res;
  return res;
};

export const initElectiveCourseOptions = (data) => {
  return data.courseOptions?.filter(
    (ec) =>
      // (ec.category === "Elective") & // combining elective property and criminal law together
      !new Set(data?.electivePreferences?.map((p) => p.id)).has(ec.id)
  );
};
