import * as React from 'react';
import withRouter from './withRouter'
import { AppBar, AppBarSection, AppBarSpacer, Avatar, Drawer, DrawerContent } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import {ProfileContent} from  "./ProfileContent";
import { GlobalLoader } from "./GlobalLoader";
import { RolesAuthRoute } from "./RolesAuthRoute";
import { useUserMyRoles } from "../services/userService";
import ApiStatus from "./ApiStatus";
import { Loader } from '@progress/kendo-react-indicators';
import { useNavigate } from "react-router-dom";
import {getUrlWithoutQuery} from "../utils/componentUtils"
import { useEffect, useState } from 'react';
import { convertTextToClassId } from '../utils/componentUtils';

let kendokaAvatar = '//www.telerik.com/account/images/AvatarImages/default.gif';

const DrawerRouterContainerPublic = props => {  
  const [expanded, setExpanded] = React.useState(true);
  const [menuItems, setMenuItems] = React.useState([
    {
      id:1,
      text: 'Sign In',
      icon: 'k-i-user',
      selected: true,
      route: '/',      
    },
    {
    id:1,
    text: 'Account Activation',
    icon: 'k-i-check-circle',    
    route: '/accountactivation',    
    }
  ]);    
  //const { data, status, isSuccess, isLoading } = useUserMyRoles();      

  // if(isLoading) return  <div className='centered'><Loader size="large" type='converging-spinner' /></div>;
  // if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;  
  // if (!data) return <div> Not found.</div>;    
    
  const handleClick = () => {
    // debugger
    setExpanded(!expanded);
  };
  const onSelect = e => {   
    //setExpanded(false);
    //console.log(e.itemTarget.props.route)
    props.router.navigate(e.itemTarget.props.route);       
    //props.history.push(e.itemTarget.props.route);
  };

  const setSelectedItem = pathName => {    
    let currentPath = menuItems.find(item => getUrlWithoutQuery(item.route) === pathName);
    if (currentPath?.text) {
      return currentPath.text;
    }
  };  
  const selected =  setSelectedItem(props?.router?.location?.pathname);
  
  const userMenuItems = menuItems.map(item => ({...item, selected: item.text === selected}));

  const useMatchMedia = (mediaQuery, initialValue) => {
    const [isMatching, setIsMatching] = useState(initialValue)
    useEffect(() => {
      const watcher = window.matchMedia(mediaQuery)
      setIsMatching(watcher.matches)
      const listener = (matches) => {
        setIsMatching(matches.matches)
      }
      if (watcher.addEventListener) {
        watcher.addEventListener('change', listener)
      } else {
        watcher.addListener(listener)
      }
      return () => {
        if (watcher.removeEventListener) {
          return watcher.removeEventListener('change', listener)
        } else {
          return watcher.removeListener(listener)
        }
      }
    }, [mediaQuery])
  
    return isMatching
  }

  const isDesktopResolution = useMatchMedia('(min-width:992px)', true)
        
  return <React.Fragment>
      <AppBar positionMode='sticky'>
        <AppBarSection>        
          <Button icon="layout" fillMode="flat" onClick={handleClick} />          
        </AppBarSection>

        <AppBarSection>
          <a href="/"><img alt="UCLA Law" height={60} src="/header.png"></img></a>
          <GlobalLoader />    
        </AppBarSection>
        <AppBarSpacer />             
      </AppBar>                  
        <Drawer 
        expanded={expanded && isDesktopResolution} mode="push" position="start" mini={true} 
        items={userMenuItems} 
        onSelect={onSelect}
        miniWidth="0"
        >
     
        <DrawerContent>                                           
          {
            menuItems.some(item => item.text === selected) ?
            menuItems.map(item => {                   
            return (item.text === selected) &&                           
                <div className="content" id={convertTextToClassId(item.text)} key={item.text}>                                                                    
                                      {props.children}
                                  </div>             
            }) : 
          <div className="content" key={'test'}>                                                                    
            {props.children}
          </div>        
        }
        </DrawerContent>
      </Drawer>      
      
      <style>{`              
              .navLinks {
                font-size: 14px;
                list-style-type: none;
                padding: 0;
                margin: 0;
                display: flex;
              }
              .navLinks  li {
                  margin: 0 10px;
              }
              .navLinks li:hover {
                  cursor: pointer;
                  color: #84cef1;
              }
              .k-drawer-content{
                height:100%;
                overflow-y:auto;
              }
              `}
        </style>
    </React.Fragment>;
};

export default withRouter(DrawerRouterContainerPublic);