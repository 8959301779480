import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useParams } from "react-router-dom";
import ApiStatus from "../../components/ApiStatus";
import { FirstYearPreferenceForm, FirstYearPreferenceFormSteps } from "./FirstYearPreferenceForm";
import { useCreateFirstYearPreferences,useFetchMyFirstYearPreference, useUpdateFirstYearPreference, useFetchCourseOptions } from "../../services/firstYearPreferenceService";
import { useFetchSetting, FIRST_YEAR_PREFERENCES_SETTING } from "../../services/settingsService";
import DOMPurify from 'dompurify';
import { useQueryClient } from "@tanstack/react-query";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { EditorComponent } from "../../components/EditorComponent";
import { RolesAuthRoute } from "../../components/RolesAuthRoute";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { useNavigate } from "react-router-dom";
import { VersionChecker } from "../../components/VersionChecker";


export const FirstYearPreferenceNew = (props) => {      
  const { data: settings, status: settingsStatus, isSuccess: settingsSuccess, isError: settingsIsError, error: settingsError } = useFetchSetting(FIRST_YEAR_PREFERENCES_SETTING);      
  const { data: myFirstYearPreference, status: myFirstYearPreferenceStatus, isSuccess: myFirstYearPreferenceSuccess, isError: myFirstYearPreferenceIsError, error: myFirstYearPreferenceError } = useFetchMyFirstYearPreference();
  const createMutation = useCreateFirstYearPreferences();
  const nav = useNavigate();
  const editRoles = ['Manager', 'Admin'];
  const editClaims = ['LawNET Development'];

  if (!settingsSuccess) return <ApiStatus status={settingsStatus} error={settingsError}></ApiStatus>;  
  // if (!myFirstYearPreference) return <ApiStatus status={myFirstYearPreferenceStatus}></ApiStatus>;  
  const handleSelect = (e) => {            
    if(e.item?.text === "Edit") {
      nav('/settings/FirstYearPreferences')
    }      
  }
  
  return (
    <>      
    <VersionChecker />
    <EditorComponent editorId={"EditorSetting_FirstYearPreferenceNew"} editRoles= {editRoles} editClaims={editClaims}  />
     {/* <div>        
        <div>{EditorUtils.getHtml(settings.visibleInfo)}</div>
    </div>              */}
    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(settings?.visibleInfo) }} /> 
    {/* <div>
        <label>Current Term</label>
        <div>{settings.currentTermId}</div>
    </div> */}
    <div className="gear-menu"> 
      <RolesAuthRoute claims={editClaims} roles={editRoles}>
          <Menu style={{maxWidth:50, float:'right'}} openOnClick={true} onSelect={handleSelect} >
            <MenuItem text="" icon="gear"  >
              <MenuItem text="Edit" />            
            </MenuItem>                
          </Menu>     
        </RolesAuthRoute>
    </div>    
    <div>
        <label>Start Date:</label>
        <p>{settings.availabilityStarts.toLocaleString()}</p>
    </div>
    <div>
        <label>Deadline:</label>
        <p>{settings.availabilityEnds.toLocaleString()}</p>
    </div>      
      
    { myFirstYearPreference?.status === "Submitted" && <p><b>You have already submitted your preferences.</b>  </p>}  

    <div style={{margin:'10px 0px'}}>
        { 
           new Date() >= settings.availabilityStarts && new Date() <= settings.availabilityEnds && <button className="btn btn-primary"  
              onClick={() => createMutation.mutate() }  
              //disabled={!(new Date() >= settings.availabilityStarts && new Date() <= settings.availabilityEnds)}            
          > { myFirstYearPreference?.status === "Saved" ? "Continue" :  myFirstYearPreference?.status === "Submitted"  ? "Edit" : "Start"} </button>   
        }   
    </div>
    <style>{`              
              label {
                font-weight:bold;                
              }             
              `}
        </style>
    </>
  );
};