import { useUserMyRoles } from "../services/userService";
//import { Navigate } from 'react-router-dom';
import ApiStatus from "../components/ApiStatus";

export function RolesAuthRoute({ children, roles, claims }) {
    
    const { data, status, isSuccess } = useUserMyRoles();    

    if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
    if (!data) return <div> Not found.</div>;    
    const canAccess = data.roles?.some(userRole => roles?.includes(userRole)) || data.claims?.some(userClaim => claims?.includes(userClaim));
    if (!canAccess) return null;      
   
    return (
        <>
            {children}
        </>
    );

    //return (<Navigate to="/ForbiddenAccess" />);
}